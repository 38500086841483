import "./App.css";
import SATImage from "./HACIENDA-SAT.jpg";
import iconoInfo from "./icon.png";
function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const rfcParam = searchParams.get("rfc");
  const folioParam = searchParams.get("folio");
  const fechaParam = searchParams.get("fecha");

  return rfcParam && folioParam && fechaParam ? (
    <div
      className="App"
      style={{
        minHeight: "819px",
        backgroundImage: "linear-gradient( #f9f9f9 , rgba(179, 142, 93,.15) )",
      }}
    >
      <center style={{ textShadow: "0 0px 0 #ffffff" }}>
        <img src={SATImage} width={"320px"} />
      </center>
      <div style={{ padding: "30px" }}>
        <div
          style={{
            border: "1px solid rgb(179, 142, 93)",
            color: "#333333",
            backgroundImage:
              "linear-gradient( #f9f9f9 , rgba(179, 142, 93,.15) )",
            padding: ".7em 15px .7em 15px",
            display: "block",
            borderRadius: ".6em",
            boxShadow: "0 3px 3px rgba(179, 142, 93, 0.8)",
            margin: "0rem 0rem 2rem 0rem",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(179, 142, 93, 0.8)",
              borderRadius: ".6em",
              padding: "7px 7px 7px 7px",
              margin: "10px 0px",
              fontSize: "15.5px",
              fontWeight: "500",
              color: "Black",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: "-4px" }}>
              <img
                src={iconoInfo}
                width={24}
                style={{ marginRight: ".5rem" }}
              />
            </div>
            La Opinión que verificó fue emitida por el Servicio de
            Administración Tributaria.
          </div>
        </div>
        <ul
          style={{
            boxShadow: "0 3px 3px rgba(179, 142, 93, 0.8)",
            margin: "1em -15px",
            borderRadius: ".6em",
            listStyle: "none",
            padding: "0px",
          }}
        >
          <li
            style={{
              borderRightWidth: "1px",
              borderLeftWidth: "1px",
              color: "#3e3e3e",
              padding: ".5em 15px",
              fontSize: "14px",
              fontWeight: "500",
              listStyle: "none",
              border: "1px solid rgb(179, 142, 93)",
              background: "rgba(179, 142, 93, 0.05)",
              backgroundImage:
                "linear-gradient( rgba(179, 142, 93, 0.06) , rgb(179, 142, 93) )",
              position: "relative",
              overflow: "visible",
              borderTopLeftRadius: ".6em",
              borderTopRightRadius: ".6em",
              textAlign: "left",
            }}
          >
            Datos Generales Enviados
          </li>
          <li
            style={{
              padding: ".7em 15px .7em 15px",
              backgroundImage:
                "linear-gradient( #f9f9f9 , rgba(179, 142, 93,.15) )",
              border: "1px solid rgb(179, 142, 93)",
              borderBottomLeftRadius: ".6em",
              borderBottomRightRadius: ".6em",
            }}
          >
            <table style={{ borderCollapse: "collapse" }}>
              <tbody style={{ borderCollapse: "collapse" }}>
                <tr>
                  <td
                    style={{
                      borderWidth: "0px",
                      borderStyle: "solid",
                      borderColor: "inherit",
                      padding: "4px 10px",
                    }}
                  >
                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "500",
                              padding: "4px 10px",
                            }}
                          >
                            Folio:
                          </td>
                          <td
                            style={{ textAlign: "left", padding: "4px 10px" }}
                          >
                            {folioParam}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "500",
                              padding: "4px 10px",
                            }}
                          >
                            RFC:
                          </td>
                          <td
                            style={{ textAlign: "left", padding: "4px 10px" }}
                          >
                            {rfcParam}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "500",
                              padding: "4px 10px",
                            }}
                          >
                            Fecha:
                          </td>
                          <td
                            style={{ textAlign: "left", padding: "4px 10px" }}
                          >
                            {fechaParam}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "500",
                              padding: "4px 10px",
                            }}
                          >
                            Sentido:
                          </td>
                          <td
                            style={{ textAlign: "left", padding: "4px 10px" }}
                          >
                            Positivo
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "4px 10px" }}></td>
                        </tr>
                        <tr>
                          <td style={{ padding: "4px 10px" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>

        <ul
          style={{
            boxShadow: "0 3px 3px rgba(179, 142, 93, 0.8)",
            margin: "1em -15px",
            borderRadius: ".6em",
            listStyle: "none",
            padding: "0px",
          }}
        >
          <li
            style={{
              borderRightWidth: "1px",
              borderLeftWidth: "1px",
              color: "#3e3e3e",
              padding: ".5em 15px",
              fontSize: "14px",
              fontWeight: "500",
              listStyle: "none",
              border: "1px solid rgb(179, 142, 93)",
              background: "rgba(179, 142, 93, 0.05)",
              backgroundImage:
                "linear-gradient( rgba(179, 142, 93, 0.06) , rgb(179, 142, 93) )",
              position: "relative",
              overflow: "visible",
              borderTopLeftRadius: ".6em",
              borderTopRightRadius: ".6em",
              textAlign: "left",
            }}
          >
            Datos Generales Consultados
          </li>
          <li
            style={{
              padding: ".7em 15px .7em 15px",
              backgroundImage:
                "linear-gradient( #f9f9f9 , rgba(179, 142, 93,.15) )",
              border: "1px solid rgb(179, 142, 93)",
              borderBottomLeftRadius: ".6em",
              borderBottomRightRadius: ".6em",
            }}
          >
            <table style={{ borderCollapse: "collapse" }}>
              <tbody style={{ borderCollapse: "collapse" }}>
                <tr>
                  <td
                    style={{
                      borderWidth: "0px",
                      borderStyle: "solid",
                      borderColor: "inherit",
                      padding: "4px 10px",
                    }}
                  >
                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "500",
                              padding: "4px 10px",
                            }}
                          >
                            Folio:
                          </td>
                          <td
                            style={{ textAlign: "left", padding: "4px 10px" }}
                          >
                            {folioParam}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "500",
                              padding: "4px 10px",
                            }}
                          >
                            RFC:
                          </td>
                          <td
                            style={{ textAlign: "left", padding: "4px 10px" }}
                          >
                            {rfcParam}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "500",
                              padding: "4px 10px",
                            }}
                          >
                            Fecha:
                          </td>
                          <td
                            style={{ textAlign: "left", padding: "4px 10px" }}
                          >
                            {fechaParam}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "500",
                              padding: "4px 10px",
                            }}
                          >
                            Sentido:
                          </td>
                          <td
                            style={{ textAlign: "left", padding: "4px 10px" }}
                          >
                            Positivo
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "4px 10px" }}></td>
                        </tr>
                        <tr>
                          <td style={{ padding: "4px 10px" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>404 error</h1>
      <h2>No hay nada que mostrar, intente mas tarde.</h2>
    </div>
  );
}

export default App;
